<template>
	<div class="xl:mt-12 xl:pt-10 overflow-y-auto data-list-container main-list-view ml-6 table-team">
		<!-- List -->
		<div>
			<!-- Main Table -->
			<vs-table
				class="mt-6"
				ref="table"
				multiple
				search
				sst
				:pagination="false"
				:data="teams"
				:max-items="limit"
				:total="totalTeams"
				v-model="selected"
				@search="onSearch"
				@sort="onSort"
				@change-page="changePage">
				<div
					slot="header"
					class="flex flex-wrap-reverse items-center flex-grow justify-between ">
          <div class="text-xl font-bold">{{$t('Teams')}}</div>
					<div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ADD NEW Team -->
            <div
              class="btn-add-new px-4 py-25 mr-4 vs-button vs-button-primary vs-button-filled rounded-lg cursor-pointer"
              @click="isShowTeam = true">
              <div class="flex items-center">
                <svg class="w-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>plus</title><path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" /></svg>
                <div class="">{{ $t('Invite') }}</div>
              </div>
            </div>


            <!-- ACTION - DROPDOWN -->
            <vs-dropdown
              class="dd-actions cursor-pointer mr-4 ml-auto"
              vs-trigger-click>
              <div
                class="px-4 py-25 shadow-drop vs-button vs-button-light rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center">
                <span class="mr-3">{{ $t('With Selected') }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <!-- Delete -->
                <vs-dropdown-item
                  :disabled="selected.length === 0">
                  <span class="flex items-center" @click="bulkDelete()">
                    <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>{{ $t('Delete') }}</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

            </div>
				</div>

				<template style="background-color: white" slot="thead">
					<vs-th>{{ $t('Name') }}</vs-th>
					<vs-th>{{ $t('Email') }}</vs-th>
					<vs-th>{{ $t('Event') }}</vs-th>
					<vs-th>{{ $t('Action') }}</vs-th>
				</template>

				<template slot-scope="{data}">
					<tbody>
						<vs-tr
							:data="item"
							v-for="(item, indexitem) in data"
							:key="indexitem">
							<!-- Name -->
								<vs-td>
									<p class="category-name font-medium truncate">
										{{ item.team_name }}
									</p>
								</vs-td>

								<!-- Email -->
								<vs-td>
									<p class="category-name font-medium truncate">{{ item.team_email ? item.team_email : '' }}</p>
								</vs-td>

                <!-- Event -->
								<vs-td>
									<p class="category-name font-medium truncate">{{ item.joined_event ? item.joined_event : '' }}</p>
								</vs-td>

								<!-- Actions -->
								<vs-td class="whitespace-no-wrap">
									<!-- details -->
									<feather-icon
										icon="UserIcon"
										svgClasses="w-5 h-5 hover:text-success stroke-current"
										title="Team Details"
										class="mr-3"
										@click.stop="showDetails(item)"
									/>
									<!-- Edit -->
									<feather-icon
										icon="EditIcon"
										svgClasses="w-5 h-5 hover:text-primary stroke-current"
										class="mr-2"
										@click.stop="editData(item)"
									/>
									<!-- Delete -->
									<feather-icon
										icon="TrashIcon"
										svgClasses="w-5 h-5 hover:text-danger stroke-current"
										@click.stop="deleteData(item)"
									/>
								</vs-td>
						</vs-tr>
					</tbody>
				</template>
			</vs-table>

      <div class="flex items-center justify-end mr-4">
        <!-- ITEMS PER PAGE -->
        <vs-dropdown
          class="cursor-pointer mr-4 items-per-page-handler"
          vs-trigger-click>
          <div
            class="px-4 py-25 border border-solid d-theme-border-grey-light  d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">
              {{ page * limit - (limit - 1) }} - {{ teams.length > 0 ? page * limit : teams.length }} of {{ totalTeams }}
            </span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="changeLimit(10)"><span>10</span></vs-dropdown-item>
            <vs-dropdown-item @click="changeLimit(25)"><span>25</span></vs-dropdown-item>
            <vs-dropdown-item @click="changeLimit(50)"><span>50</span></vs-dropdown-item>
            <vs-dropdown-item @click="changeLimit(100)"><span>100</span></vs-dropdown-item>
            <vs-dropdown-item @click="changeLimit(200)"><span>200</span></vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <!-- Pagination -->
        <div>
          <vs-pagination
            class="mt-3"
            :total="totalPages"
            :max-items="limit"
            :size-array="totalTeams"
            v-model="page">
          </vs-pagination>
        </div>
      </div>
		</div>


		<!-- popup invited attendee -->
		<vs-popup classContent="popup-example"  :title="$t('Event Invitations')" :active.sync="showDialogInvite">
			{{$t('Are you sure want to invite')}} {{selected.length}} {{$t('Attendees')}}?
			({{$t('Select at least one for Invitation')}})
			<vs-checkbox v-model="isSms" class="mb-4 mt-4">{{$t('SMS')}}</vs-checkbox>
			<vs-checkbox v-model="isEmail">{{$t('Email')}}</vs-checkbox>
			<vs-divider/>
			<vs-row vs-type="flex" vs-justify="flex-end">
				<vs-button color="#B8B8B8" class="mr-3" type="filled" @click="showDialogInvite = false">{{$t('Cancel')}}</vs-button>
				<vs-button color="primary" :disabled="!isEmail && !isSms" type="filled" @click="inviteBulk()">{{$t('Confirm')}}</vs-button>
			</vs-row>
		</vs-popup>

		<!-- Attendee Profile -->
		<team-details
      v-if="isShowTeamDetails"
			:event="event"
			:item="selectedItem"
			:is-visible.sync="isDetailsVisible"
			@edit="onEdit"
      :events="events"
			@remove="onRemove"
			@close="isDetailsVisible = false"
		/>

		<!-- My Profile -->
		<modal-team
			:show="isShowTeam"
			:team="modalData"
			:isDetailsFromParent="isDetailsFromParent"
			:isEditFromParent="isEditFromParent"
			:eventId="eventId"
      :events="events"
			@add="insert"
			@close="closeTeam()"
			@showDeleteTeam="showDeleteTeam"
		/>

	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import 'vue-swatches/dist/vue-swatches.min.css';
import {
  dateFormat,
  duplicateVar,
  delay,
  getAxiosErrorMessage,
} from '@/lib/helper';
import dayjs from 'dayjs';
import teamsApi from '@/api/team';
import eventsApi from '@/api/event';
import ModalTeam from '@/components/teams/ModalTeam.vue';
import TeamDetails from '@/components/teams/form/TeamDetails.vue';

export default {
  name: 'Teams',
  components: {
    TeamDetails,
    ModalTeam,
  },
  props: {
  },
  data() {
    return {
      isShowTeamDetails: false,
      event: null,
      keyword: '',
      teams: [],
      selectedTeam: null,
      isFetching: false,
      isAll: false,
      page: 1,
      limit: 10,
      orderBy: 'created_at',
      sortBy: 'asc',
      totalPages: 0,
      totalTeams: 0,
      selected: [],
      selectedItem: null,
      isFormVisible: false,
      isDeleteVisible: false,
      isDeleting: false,
      isMounted: false,
      isCheckingIn: false,
      isCheckingOut: false,
      addNewDataSidebar: false,
      AddNewDataDialogBroadcast: false,
      modalData: {},
      dialogDataBroadcast: {},
      isImporting: false,
      isPreviewImport: false,
      isExporting: false,
      isImportAccept: false,
      import: false,
      listExport: false,
      dialogImport: false,
      type: 'team',
      isDetailsVisible: false,
      showFileImport: false,
      nameImportFile: '',
      files: '',
      isCreatingGroup: false,
      groupName: '',
      groupColor: '#2ECC70',
      groupType: 'attendee',
      isGroupFormVisible: false,
      fieldGroupId: null,
      fieldGroup: null,
      isRemoveFieldGroupVisible: false,
      showDialogInvite: false,
      isSms: false,
      isEmail: false,
      isFilterAll: false,
      isFilterInvitation: false,
      isFilterLoggedIn: false,
      isFilterConfirmed: false,
      status: '',
      isModerator: '["moderator"]',
      // modal team
      isShowTeam: false,
      isEditFromParent: false,
      isDetailsFromParent: false,
      // modalbroadcast
      isModalBroadcast: false,
      isApproving: false,
      // events
      events: [],
    };
  },
  methods: {
    showDeleteTeam(item) {
      this.deleteData(item);
    },
    closeTeam() {
      this.isShowTeam = false;
      this.modalData = {};
      this.isDetailsFromParent = false;
      this.isEditFromParent = false;
    },
    showDetails(data) {
      this.modalData = duplicateVar(data);
      this.isShowTeam = true;
      this.isDetailsFromParent = true;
    },
    editData(data) {
      this.isShowTeam = true;
      this.modalData = duplicateVar(data);
      this.isEditFromParent = true;
    },
    fetch(reset) {
      this.$vs.loading();
      this.isFetching = true;
      if (reset) {
        this.page = 1;
        this.teams = [];
      }
      const keyword = this.keyword;
      const params = {
        page: this.page,
        limit: this.limit,
        order_by: this.orderBy,
        sort_by: this.sortBy,
        role: 'team',
      };
      if (this.status) params.status = this.status;
      if (keyword !== '') {
        params.keyword = keyword;
      }
      const callback = (response) => {
        const teams = response.data;
        const lastPage = response.lastPage;
        this.teams = teams;
        this.isAll = teams.length === 0 || teams.length < this.limit;
        // this.page++;
        if (lastPage) {
          this.totalPages = lastPage;
        }
        this.totalTeams = response.total;
        this.isFetching = false;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.$vs.loading.close();
      };
      teamsApi.getUsers(params, callback, errorCallback);
    },
    deleteData(item) {
      const name = item ? item.team_name : this.$t('the attendee');
      this.isDetailsVisible = false;
      this.selectedItem = duplicateVar(item);
      this.selected = [item];
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: `${this.$t('Are you sure want to delete')} "${name}"?`,
        accept: this.deleteRecords,
        acceptText: this.$t('Delete'),
      });
    },
    bulkDelete() {
      const total = this.selected.length;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: `${this.$t('Are you sure want to delete')} ${total} ${this.$t('teams')}?`,
        accept: this.deleteRecords,
        acceptText: this.$t('Delete'),
      });
    },
    onSearch(keyword) {
      delay(() => {
        this.keyword = keyword ? keyword.trim() : '';
        this.fetch(true);
      }, 1000);
    },
    onSort(sortKey, sortType) {
      this.orderBy = sortKey;
      this.sortBy = sortType;
      if (this.orderBy && this.sortBy) this.fetch(true);
    },
    deleteRecord() {
      this.$vs.loading();
      const item = this.selectedItem;
      const title = this.$t('Attendees');
      const callback = (response) => {
        this.remove(item.id);
        const message = response.message;
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.$vs.loading.close();
        this.closeTeam();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.$vs.loading.close();
      };
      teamsApi.delete(item.id, callback, errorCallback);
    },
    deleteRecords() {
      const ids = this.selected.map(({ id }) => id);
      const title = this.$t('Attendees');
      const callback = (response) => {
        ids.forEach((id) => {
          this.remove(id);
        });
        const message = response.message;
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      teamsApi.bulkDelete(ids, callback, errorCallback);
    },
    insert(item) {
      const index = this.teams.findIndex(({ id }) => id === item.id);
      if (index === -1) {
        this.teams.push(item);
        this.totalTeams++;
      } else {
        this.$set(this.teams, index, item);
      }
    },
    remove(itemId) {
      const index = this.teams.findIndex(({ id }) => id === itemId);
      if (index !== -1) {
        this.teams.splice(index, 1);
        this.totalTeams--;
      }
    },
    changeLimit(value) {
      this.limit = value;
    },
    changePage(page) {
      this.page = page;
    },
    onSelectAll(event) {
      const elem = event.target;
      const isCheckbox = elem.classList.contains('vs-checkbox--input');
      const isCheckAll = !!elem.closest('.vs-table--thead');
      if (isCheckbox && isCheckAll) {
        const isChecked = elem.checked;
        if (isChecked) {
          this.selected = this.teams;
        } else {
          this.selected = [];
        }
      }
    },
    updateTeamData(team) {
      const index = this.teams.findIndex(({ id }) => id === team.id);
      if (index !== -1) this.$set(this.teams, index, team);
    },
    onEdit(item) {
      this.isDetailsVisible = false;
      this.editData(item);
    },
    onRemove(item) {
      this.isDetailsVisible = false;
      this.deleteData(item);
    },
    osloTime(date) {
      // const timeZone = 'Europe/London';
      const storedDate = date;
      const userFormat = 'D.M.YYYY H:mm:ss'; // Standard Day.js format
      const displayedDate = (dayjs(new Date(storedDate).toLocaleString('en-US', { timeZone: 'Europe/Oslo' })).format(userFormat));
      return displayedDate;
    },
    filterInvitation() {
      this.status = 'invitation_sent';
      this.isFilterInvitation = true;
      this.isFilterLoggedIn = false;
      this.isFilterConfirmed = false;
      this.isFilterAll = false;
    },
    filterLoggedIn() {
      this.status = 'logged_in';
      this.isFilterLoggedIn = true;
      this.isFilterInvitation = false;
      this.isFilterConfirmed = false;
      this.isFilterAll = false;
    },
    filterConfirmed() {
      this.status = 'confirmed';
      this.isFilterConfirmed = true;
      this.isFilterInvitation = false;
      this.isFilterLoggedIn = false;
      this.isFilterAll = false;
    },
    filterAll() {
      this.status = '';
      this.isFilterAll = true;
      this.isFilterInvitation = false;
      this.isFilterLoggedIn = false;
    },
    dateFormat(date) {
      return dateFormat(date, 'DD. MM. YYYY HH:mm');
    },
    fetchEvents() {
      this.$vs.loading();
      this.isFetching = true;
      this.events = [];
      const params = {
        page: 1,
        limit: 1000,
        order_by: this.orderBy,
        sort_by: 'desc',
        is_archived: 0,
      };

      const callback = (response) => {
        const events = response.data;
        this.events = events;
        this.fetch();
        this.isFetching = false;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.$vs.loading.close();
      };
      eventsApi.getList(params, callback, errorCallback);
    },
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      user: 'user',
      isLoggedIn: 'isLoggedIn',
    }),
    eventSlug() {
      return this.$route.params.eventSlug;
    },
    eventId() {
      return this.event && this.event.id ? this.event.id : null;
    },

  },
  created() {
    this.fetchEvents();
    this.filterAll();
    document.addEventListener('click', this.onSelectAll);
  },
  destroyed() {
    document.removeEventListener('click', this.onSelectAll);
  },
  mounted() {
    this.isMounted = true;
    // overide no available text data in vue sax
    const messageNotAvailableTable = this.$t('No data Available');
    // Retrieve the element
    const notDataTableElement = document.querySelector('.not-data-table');
    // Check if the element exists
    if (notDataTableElement && !this.isFetching) {
      // Add localization (replace the text with your localized version)
      notDataTableElement.innerHTML = messageNotAvailableTable;
    }
  },
  watch: {
    page() {
      this.fetch();
    },
    status() {
      this.fetch(true);
    },
    limit() {
      this.fetch(true);
    },
    $route() {
      this.fetch();
    },
  },
};
</script>
<style>
[dir] .preview-import.con-vs-dialog .vs-dialog {
	max-width: 910px;
	min-width: 625px;
	width: 750px;
	max-height: 500px;
}
.preview-import{
	z-index:999999;
}
.table-preview{
	max-height: 370px;
	max-width: 750px;
	overflow-y: scroll;
	overflow-x: scroll;
}
.status-chip.con-vs-chip{
    border-radius: 5px!important;
	min-width: 93.66px;
}
.roles-chip.con-vs-chip{
    border-radius: 5px!important;
	min-width: 70.66px;
}
.status-failed-chip.con-vs-chip{
    border-radius: 5px!important;
	min-width: 110.66px;
}
.wrapper-attendees{
	position: absolute;
}
.chip-wrapper{
	max-width: 100px!important;
}
.btn-add-new-invite{
	min-height: 38px;
}
.icon-filter{
	position: absolute;
	right: 0;
	padding-right: 12px;
  font-size: 20px;
}
.icon-filter:before {
    content: "\e875";
    color: white;
}

.table-team.main-list-view .vs-con-table .vs-table--header {
  /* margin-right: 60px!important; */
}

.import-modal.con-vs-dialog .vs-dialog {
		min-width: 600px!important;
}


</style>
