<template>
	<div>
		<div v-if="dataImg == null" class="text-center pointer" @click="$refs.updateImgInput.click()">
		<vs-avatar v-if="!team" size="70px">
			<img src="@/assets/images/file-icons/default-img.jpg" alt="asd" class="responsive pointer" />
		</vs-avatar>
		<vs-avatar class="text-4xl" :color="getColor(team && team.name)" :text="getInitial(dataName)" v-if="team" size="70px">
		</vs-avatar>
		</div>
		<div v-if="dataImg != null" class="text-center" @click="$refs.updateImgInput.click()">
			<img :src="dataImg" alt="img" style="width: 70px; height: 70px" class="responsive rounded-full" />
		</div>
		<div class="space-y-6">
			<div>
				<div class="text-gray border-bottom-2">{{ $t('Name') }}</div>
				<div>{{dataName}}</div>
			</div>
			<div>
				<div class="text-gray">{{ $t('Email') }}</div>
				<div>{{dataEmail}}</div>
			</div>
			<div class="select-ids">
				<div class="text-gray">{{ $t('Events') }}</div>
				<div>{{dataJoined}}</div>
      </div>
		</div>
		<div class="mt-8 flex justify-between items-center">
			<div class="text-blue pointer" @click="showDeleteTeam()">
				Delete this profile
			</div>
			<vs-button
				@click="editToggle()"
				color="primary"
				type="filled"
				class="submit-button"
				>
				{{ $t('Edit') }}
			</vs-button>
		</div>
	</div>
</template>

<script>
/* eslint-disable camelcase */
import {
  duplicateVar,
  getFirstCharacter,
  generateColorByAlphabet,
} from '@/lib/helper';

export default {
  components: {
  },
  props: {
    team: {
      type: Object,
      default: () => {},
    },
    eventId: {
      type: Number,
      default: null,
    },
    events: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      languageOptions: [
        { value: 'no', text: 'Norsk' },
        { value: 'en', text: 'English' },
      ],
      dataId: null,
      dataEventId: null,

      dataName: '',
      dataCompany: '',
      dataEmail: '',
      dataLanguage: 'en',

      dataJoined: '',
      dataEventIds: [],

      dataImg: null,
      isSaving: false,
      isUploading: false,
    };
  },
  computed: {
  },
  methods: {
    showDeleteTeam() {
      this.$emit('showDeleteTeam', this.team);
    },
    setData() {
      const team = duplicateVar(this.team);
      this.dataId = team.id;
      this.dataEmail = team.team_email;
      this.dataName = team.team_name;
      this.dataJoined = team.joined_event;
      const resultArray = team.joined_event_ids.split(',').map(Number);
      this.dataEventIds = resultArray;
    },

    editToggle() {
      this.$emit('editToggle');
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
  },
  mounted() {
  },
  created() {
    this.setData();
  },
  destroyed() {
  },
};
</script>
